import Http from "@/services/Http";

export default {
  getAllSubscribedProgramDiscussions(data) {
    return Http().post("subscribed/program/discussions/all", data);
  },
  getSubscribedProgramDiscussionComments(data) {
    return Http().post("subscribed/program/discussion/comments", data);
  },
  getSubscribedProgramDiscussionDetails(data) {
    return Http().post("subscribed/program/discussion/view", data);
  },
  saveDiscussion(data) {
    return Http().post("subscribed/program/discussion/save", data);
  },
  postDiscussionComment(data) {
    return Http().post("subscribed/program/discussion/comment/post", data);
  },
  getDiscussion(data) {
    return Http().post("discussion/edit", data);
  },
};
