<template>
  <div class="_section-body">
    <div v-if="discussionSlotLoading" class="loading">Loading...</div>
    <div v-else-if="discussions">
      <ul class="list-unstyled discussion-list">
        <li v-for="slot in discussions" :key="slot.id">
          <div class="discussion-card">
            <div class="_card-head">
              <div class="thumbnail">
                <img
                  v-if="slot.author.image != null"
                  :src="slot.author.image"
                  alt=""
                />
                <img v-else src="../assets/thumb.png" alt="..." />
              </div>
              <h6>{{ slot.author.author_name }}</h6>
              <p>{{ convertedTimeForUser(slot.created_at) }}</p>
              <!-- For editing the discussion threads -->
              <a
                v-if="slot.post_count == 0 && slot.created_by == user.id"
                href="#"
                @click.prevent="$emit('editDiscussion', slot.id)"
              >
                <img src="../assets/pen-solid.svg" alt="" />
              </a>
            </div>
            <div class="_card-body">
              <p class="main" v-if="toggleTitle">
                {{ slot.discussion }}
              </p>
              <div class="subcomments">
                <ul class="list-unstyled">
                  <li>
                    <p v-if="!toggleTitle">
                      {{ slot.discussion }}
                    </p>
                    <p v-if="toggleTitle">
                      Course: {{ slot.program.program_title }}
                    </p>
                    <p v-if="toggleTitle">
                      Subject: {{ slot.subject.subject_title }}
                    </p>
                    <p v-if="toggleTitle">
                      Teacher: {{ slot.teacher.teacher_name }}
                    </p>
                    <div class="info" v-if="togglePostCount">
                      <router-link
                        :to="{
                          name: 'DiscussionBoard',
                          params: {
                            program_id: slot.program_id,
                            discussion_id: slot.id,
                          },
                        }"
                      >
                        <span>
                          {{ slot.post_count }}
                          <img
                            src="../assets/comment-icon.svg"
                            alt=""
                            title="View Comments"
                          />
                        </span>
                      </router-link>
                    </div>
                    <div class="info" v-if="togglePostCount == false">
                      <!-- for editing the discussion comments -->
                      <a
                        v-if="slot.created_by == user.id"
                        href="#"
                        @click.prevent="$emit('editDiscussion', slot.id)"
                      >
                        <span
                          ><img src="../assets/pen-solid.svg" alt=""
                        /></span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging-wrapper py-3">
        <v-pagination
          v-model="discussionPage"
          :pages="totalDiscussionPages"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="discussionPaginateUpdateHandler"
          v-if="totalDiscussions > 0"
        />
      </div>
      <EmptyList v-if="totalDiscussions == 0" :messageText="emptyMessage" />
    </div>
  </div>
</template>

<script>
import DiscussionService from "@/services/DiscussionService";
import EmptyList from "@/components/EmptyList.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Timezone from "@/services/Timezone";

export default {
  name: "DiscussionSlot",
  components: {
    EmptyList,
    VPagination,
  },
  props: {
    togglePostCount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    user() {
      let user = this.$store.state.auth.user;
      if (user.length == undefined) {
        user = {};
      } else {
        user = JSON.parse(user);
      }
      return user;
    },
  },
  data() {
    return {
      discussionSlotLoading: true,
      toggleTitle: true,
      emptyMessage: "",
      // Initial page number for discussions if any
      discussionPage: 1,
      currentDiscussionPage: 1,
      totalDiscussionPages: 0,
      totalDiscussions: 0,
      discussions: [],
    };
  },
  created() {
    // console.log(this.$route.name);
    this.switchRoute();
  },
  methods: {
    switchRoute() {
      let params = {};
      let routeName = this.$route.name;
      let program_id =
        this.$route.params.program_id != undefined
          ? this.$route.params.program_id
          : "";
      let discussion_id =
        this.$route.params.discussion_id != undefined
          ? this.$route.params.discussion_id
          : "";
      if (routeName === "MyDiscussionBoard") {
        params = {
          page: this.currentDiscussionPage,
        };
        this.emptyMessage = "No discussions found!";
        this.fetchAllSubscribedProgramDiscussions(params);
      } else if (routeName === "DiscussionBoard") {
        this.toggleTitle = false;
        params = {
          page: this.currentDiscussionPage,
          program_id: program_id,
          discussion_id: discussion_id,
        };
        this.emptyMessage = "No comments found!";
        this.fetchSubscribedProgramDiscussionComments(params);
      }
    },
    convertedTimeForUser(date) {
      let convertedDay = Timezone.convertedTimeZone(date).fromNow();
      return convertedDay;
    },
    async fetchAllSubscribedProgramDiscussions(params) {
      await DiscussionService.getAllSubscribedProgramDiscussions(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.discussionSlotLoading = false;
            // console.log(response.data.discussions.data);
            this.discussions = response.data.discussions.data;
            // console.log(this.discussions);
            // works only when totalDiscussionPages should be the last_page value
            this.totalDiscussionPages = response.data.discussions.last_page;
            this.currentDiscussionPage = response.data.discussions.current_page;
            this.totalDiscussions = response.data.discussions.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchSubscribedProgramDiscussionComments(params) {
      await DiscussionService.getSubscribedProgramDiscussionComments(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.discussionSlotLoading = false;
            this.discussions = response.data.comments.data;
            // console.log(this.discussions);
            // works only when totalDiscussionPages should be the last_page value
            this.totalDiscussionPages = response.data.comments.last_page;
            this.currentDiscussionPage = response.data.comments.current_page;
            this.totalDiscussions = response.data.comments.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    discussionPaginateUpdateHandler(selectedPage) {
      if (this.currentDiscussionPage !== selectedPage) {
        this.currentDiscussionPage = selectedPage;
        this.switchRoute();
      }
    },
    getDiscussion: function (id) {
      const data = {
        discussion_id: id,
      };
      DiscussionService.getDiscussion(data)
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            this.programId = result.data.discussion.program_id;
            this.discussion = result.data.discussion.discussion;
            this.getSubjects();
            this.subjectId = result.data.discussion.subject_id;
            this.discussionId = result.data.discussion.id;
          } else if (result.data.status == "ERROR") {
            this.$toast.error(result.data.message);
            this.$refs.closeModal.click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
